import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';
import BulletinBoardPost from '../models/BulletinBoardPost';
import BulletinBoardCategory from '../models/BulletinBoardCategory';

export default class BulletinBoardStore {
  @observable bulletinBoardPosts = fromPromise.resolve([]);

  @observable bulletinBoardCategories = fromPromise.resolve([]);

  constructor(requests) {
    this.requests = requests;
  }

  @action createBulletinBoardPost(item, resolve, reject) {
    return this.requests.BulletinBoardPosts.create(item).then((bulletinBoardPost) => {
      console.log('Created post: ', bulletinBoardPost);
      const newBulletinBoardPost = BulletinBoardPost.fromJsonProperties(bulletinBoardPost);
      this.bulletinBoardPosts.value = [].concat(newBulletinBoardPost, this.bulletinBoardPosts.value);

      resolve(newBulletinBoardPost);
    }).catch((err) => reject(err));
  }

  @action updateBulletinBoardPost(item, resolve, reject) {
    this.requests.BulletinBoardPosts.update(item).then((updatedBulletinBoardPost) => {
      resolve(BulletinBoardPost.fromJsonProperties(updatedBulletinBoardPost));
    }).catch((err) => reject(err));
  }

  @action async getAllBulletinBoardPosts(resolve, reject) {
    return this.requests.BulletinBoardPosts.getAll().then((json) => {
      const bulletinBoardPostObjects = json.map((bulletinBoardPost) => BulletinBoardPost.fromJsonProperties(bulletinBoardPost));
      resolve(bulletinBoardPostObjects);
    }).catch((err) => reject(err));
  }

  @action async getBulletinBoardPosts() {
    this.bulletinBoardPosts = fromPromise(new Promise((resolve) => this.requests.BulletinBoardPosts.get().then((json) => {
      const bulletinBoardObjects = json.map((bulletinBoardPost) => BulletinBoardPost.fromJsonProperties(bulletinBoardPost));
      resolve(bulletinBoardObjects);
    })));
  }

  @action async getBulletinBoardCategoryPosts(categoryId, resolve, reject) {
    return this.requests.BulletinBoardPosts.getByCategory(categoryId).then((json) => {
      const bulletinBoardObjects = json.map((bulletinBoardPost) => BulletinBoardPost.fromJsonProperties(bulletinBoardPost));
      resolve(bulletinBoardObjects);
    }).catch((err) => reject(err));
  }

  @action createBulletinBoardCategory(item, resolve, reject) {
    return this.requests.BulletinBoardCategories.create(item).then((bulletinBoardCategory) => {
      const newBulletinBoardCategory = BulletinBoardCategory.fromJsonProperties(bulletinBoardCategory);
      this.bulletinBoardCategories.value = [].concat(newBulletinBoardCategory, this.bulletinBoardCategories.value);

      resolve(newBulletinBoardCategory);
    }).catch((err) => reject(err));
  }

  @action updateBulletinBoardCategory(item, resolve, reject) {
    return this.requests.BulletinBoardCategories.update(item).then((bulletinBoardCategory) => {
      //   const newBulletinBoardCategory = BulletinBoardCategory.fromJsonProperties(bulletinBoardCategory);
      //   this.bulletinBoardCategories.value = [].concat(newBulletinBoardCategory, this.bulletinBoardCategories.value);

      //   resolve(newBulletinBoardCategory);
      resolve(BulletinBoardCategory.fromJsonProperties(bulletinBoardCategory));
    }).catch((err) => reject(err));
  }

  @action async getAllBulletinBoardCategories(resolve, reject) {
    return this.requests.BulletinBoardCategories.getAll().then((json) => {
      const bulletinBoardCategoryObjects = json.map((bulletinBoardCategory) => BulletinBoardCategory.fromJsonProperties(bulletinBoardCategory));
      resolve(bulletinBoardCategoryObjects);
    }).catch((err) => reject(err));
  }

  @action async deleteBulletinBoardPost(id, resolve, reject) {
    // return this.requests.Validities.del(validity).then(() => {
    //   const updatedValidities = ctxUser.validities.filter((oldValidity) => oldValidity.id !== validityId);
    //   ctxUser.validities.replace(updatedValidities);

    //   resolve(true);
    // }).catch((err) => reject(err));

    return this.requests.BulletinBoardPosts.del(id).then(() => {
      resolve(true);
    }).catch((err) => reject(err));
  }

  @action purgePostFile(postId, signedId, resolve, reject) {
    return this.requests.BulletinBoardPosts.purgeFile(postId, signedId).then((updatedBulletinBoardPost) => {
      resolve(BulletinBoardPost.fromJsonProperties(updatedBulletinBoardPost));
    }).catch((err) => reject(err));
  }

  @action async deleteBulletinBoardCategory(id, resolve, reject) {
    return this.requests.BulletinBoardCategories.del(id).then(() => {
      resolve(true);
    }).catch((err) => reject(err));
  }
}
