import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
import { IconButton } from '@rmwc/icon-button';
import Snackbar from '@material-ui/core/Snackbar';
import LoadingSpinner from '../shared/LoadingSpinner';
import ChatRoomRow from './ChatRoomRow';
import BulletinBoardPostsHeader from '../bulletin-board-posts/employee/BulletinBoardPostsHeader';
import BulletinBoardPostRow from '../bulletin-board-posts/employee/BulletinBoardPostRow';
import BulletinBoardCategoryRow from '../bulletin-board-posts/employee/BulletinBoardCategoryRow';
import { appcontentsize } from '../styles/inline-styles';

const rearrangeRooms = (currentUser, rooms) => [
  ...rooms.map((room) => (currentUser.invitationsByWorkOrderId[room.workorder.id] != null ? room : null)),
  ...rooms.map((room) => (currentUser.invitationsByWorkOrderId[room.workorder.id] == null ? room : null)),
].filter((item) => item != null);

const renderChatrooms = (currentUser, rooms, unreadMessages) => {
  const chatRooms = rearrangeRooms(currentUser, rooms || []);
  return (
    <div {...{
      style: appcontentsize,
    }}
    >
      {
        chatRooms.length > 0 ? (
          chatRooms.map((room) => (
            <ChatRoomRow
              key={`chat-room-${room.id}`}
              room={room}
              unreadMessages={unreadMessages[room.id] != null ? unreadMessages[room.id].length : 0}
            />
          ))
        ) : (
          <p
            style={{ textAlign: 'center' }}
          >
            Viestiryhmiä ei löytynyt
          </p>
        )
      }
    </div>
  );
};

const renderBulletinBoardCategories = (currentUser, openBulletinBoardCategory) => (
  <div {...{
    style: appcontentsize,
  }}
  >
    {
      currentUser.bulletinBoardCategories.length > 0 ? (
        currentUser.bulletinBoardCategories.map((category) => (
          <BulletinBoardCategoryRow
            key={`bulletin-board-category-${category.id}`}
            bulletinBoardCategory={category}
            openBulletinBoardCategory={openBulletinBoardCategory}
          />
        ))
      ) : (
        <p
          style={{ textAlign: 'center' }}
        >
          Ilmoituksia ei löytynyt
        </p>
      )
    }
  </div>
);

const renderBulletinBoardPosts = (openedCategory, closeBulletinBoardCategory) => (
  <div {...{
    style: appcontentsize,
  }}
  >
    <BulletinBoardPostsHeader bulletinBoardCategory={openedCategory} closeBulletinBoardCategory={closeBulletinBoardCategory} />
    <div style={{ marginTop: '25px' }}>
      {
        openedCategory.posts.length > 0 && (
          openedCategory.posts.map((post) => (
            <BulletinBoardPostRow
              key={`bulletin-board-post-${post.id}`}
              bulletinBoardPost={post}
            />
          ))
        )
      }
    </div>
  </div>
);

@inject('uiStore', 'chatStore', 't', 'notificationStore')
@observer
class Chats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      feedbackMessage: '',
      snackbarOpen: false,
    };
    const { chatStore, uiStore: { currentView: { messagesTab } } } = this.props;
    if (!messagesTab || messagesTab === 0) {
      chatStore.refreshRooms();
    }

    this.state = {
      activeTab: messagesTab || 0,
    };
  }

  openBulletinBoardCategory = (bulletinBoardCategory) => {
    const { uiStore } = this.props;
    uiStore.showBulletinBoardCategory(bulletinBoardCategory.id);
    // this.setState({
    //   bulletinBoardCategoryOpen: true,
    //   openedCategory: bulletinBoardCategory,
    // });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  }

  async handleNotificationToggle() {
    const { notificationStore } = this.props;

    this.setState({ loading: true, feedbackMessage: '', snackbarOpen: false });

    try {
      if (notificationStore.isSubscribed) {
        // Disable notifications
        await notificationStore.unsubscribeUser();
        this.setState({ feedbackMessage: 'Ilmoitukset poistettu käytöstä', snackbarOpen: true });
      } else {
        // Enable notifications
        await notificationStore.subscribeUser();
        this.setState({ feedbackMessage: 'Ilmoitukset otettu käyttöön', snackbarOpen: true });
      }
    } catch (error) {
      this.setState({ feedbackMessage: 'Tapahtui virhe, yritä uudelleen', snackbarOpen: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { chatStore, uiStore: { currentUser, currentUser: { accountInfo: { bulletinBoardEnabled }, bulletinBoardCategories } }, notificationStore } = this.props;
    const { rooms, unreadMessages } = chatStore;
    const { activeTab, bulletinBoardCategoryOpen, openedCategory } = this.state;
    const { loading, feedbackMessage, snackbarOpen } = this.state;

    // if (bulletinBoardEnabled && categorizedBulletinBoardPosts && categorizedBulletinBoardPosts.length > 0) {
    if (bulletinBoardEnabled && bulletinBoardCategories && bulletinBoardCategories.length > 0) {
      return (
        <>
          <TabBar
            activeTabIndex={activeTab}
            onActivate={(evt) => this.setState({ activeTab: evt.detail.index })}
            style={{
              ...appcontentsize,
              width: '25wv',
            }}
          >
            <Tab icon="message">Keikan viestit</Tab>
            <Tab icon="assignment">Ilmoitustaulu</Tab>
          </TabBar>
          {loading ? (
            <div style={{ transform: 'scale(0.75)' }}>
              <LoadingSpinner />
            </div>
          ) : (
            <div style={
              {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '12px',
              }
            }
            >
              <IconButton
                style={{ color: 'white' }}
                icon={notificationStore.isSubscribed ? 'notifications_off' : 'notifications'}
                onClick={() => this.handleNotificationToggle()}
              />
              {' '}
              {notificationStore.isSubscribed ? 'Poista ilmoitukset käytöstä' : 'Ota ilmoitukset käyttöön'}
            </div>
          )}
          <Snackbar
            open={snackbarOpen}
            message={feedbackMessage}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
          />

          {activeTab === 0 && (
            <>
              {rooms.case({
                pending: () => <p>Ladataan viestejä...</p>,
                rejected: () => <p>Viestejä ei ole ladattu, yritä kohta uudelleen!</p>,
                fulfilled: () => renderChatrooms(currentUser, rooms.value, unreadMessages),
              })}
              <p
                style={{ fontSize: '12px', margin: 'auto 0 20px 0' }}
              >
                Viestiryhmät piilotetaan 15 päivän päästä keikan päättymisestä
              </p>
            </>
          )}
          {activeTab === 1 && !bulletinBoardCategoryOpen && renderBulletinBoardCategories(currentUser, this.openBulletinBoardCategory)}
          {activeTab === 1 && bulletinBoardCategoryOpen && renderBulletinBoardPosts(openedCategory, this.closeBulletinBoardCategory)}
        </>
      );
    }

    return (
      <>
        {loading ? (
          <div style={{ transform: 'scale(0.75)' }}>
            <LoadingSpinner />
          </div>
        ) : (
          <div style={
            {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '12px',
            }
          }
          >
            <IconButton
              style={{ color: 'white' }}
              icon={notificationStore.isSubscribed ? 'notifications_off' : 'notifications'}
              onClick={() => this.handleNotificationToggle()}
            />
            {' '}
            {notificationStore.isSubscribed ? 'Poista ilmoitukset käytöstä' : 'Ota ilmoitukset käyttöön'}
          </div>
        )}
        <Snackbar
          open={snackbarOpen}
          message={feedbackMessage}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
        />

        {rooms.case({
          pending: () => <p>Ladataan viestejä...</p>,
          rejected: () => <p>Viestejä ei ole ladattu, yritä kohta uudelleen!</p>,
          fulfilled: () => renderChatrooms(currentUser, rooms.value, unreadMessages),
        })}
        <p
          style={{ fontSize: '12px', margin: 'auto 0 20px 0' }}
        >
          Viestiryhmät piilotetaan 15 päivän päästä keikan päättymisestä
        </p>
      </>
    );
  }
}

export default Chats;
