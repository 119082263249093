import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { appcontentsize } from '../../styles/inline-styles';
import BulletinBoardPostsHeader from './BulletinBoardPostsHeader';
import BulletinBoardPostRow from './BulletinBoardPostRow';
import BulletinBoardPostViewDialog from './BulletinBoardPostViewDialog';
// import BulletinBoardPost from '../../models/BulletinBoardPost';
import LoadingSpinner from '../../shared/LoadingSpinner';

@inject('uiStore', 't', 'bulletinBoardStore')
@observer
class BulletinBoardCategoryView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openedPost: {},
      postViewDialogOpen: false,
      categoryBulletinBoardPosts: [],
      loadingPosts: false,
    };
  }

  // componentDidMount() {
  //   const { uiStore } = this.props;
  //   const categoryId = uiStore.currentView.id;
  //   console.log('LOOKING FOR ID: ', categoryId);
  //   console.log('FROM: ', uiStore.currentUser.categorizedBulletinBoardPosts);
  //   this.openedCategory = uiStore.currentUser.categorizedBulletinBoardPosts.find((category) => category.id === categoryId); // chatStore.findById(uiStore.currentView.id);
  //   // this.cable = actionCableStore.cableOn && room
  //   //   ? actionCableStore.subscribe({ channel: 'ChatRoomChannel', chat_id: room.id }, this.handleCableData)
  //   //   : null;
  // }

  componentDidMount() {
    const { uiStore, bulletinBoardStore } = this.props;
    const categoryId = uiStore.currentView.id;

    this.setState({
      loadingPosts: true,
    });
    const getPromise = fromPromise(new Promise((resolve, reject) => bulletinBoardStore.getBulletinBoardCategoryPosts(categoryId, resolve, reject)));
    when(
      () => getPromise.state !== 'pending',
      () => {
        getPromise.case({
          pending: () => { },
          rejected: (err) => {
            console.log('Haku epäonnistui', err);
            this.setState({
              loadingPosts: false,
            });
          },
          fulfilled: (res) => {
            console.log('GOT POSTS: ', res);
            // const bulletinBoardPosts = res.map(BulletinBoardPost.fromJsonProperties);
            // const { bulletinBoardCategories } = this.state;
            // const foundBulletinBoardCategoryIndex = bulletinBoardCategories.findIndex((post) => post.id === res.id);
            // const updatedBulletinBoardCategories = [...bulletinBoardCategories];
            // if (foundBulletinBoardCategoryIndex !== -1) {
            //   updatedBulletinBoardCategories[foundBulletinBoardCategoryIndex] = res;
            // }
            this.setState({
              categoryBulletinBoardPosts: res,
              loadingPosts: false,
            });
          },
        });
      },
    );
    // bulletinBoardStore.getCategoryPosts()
  }

  closeBulletinBoardCategory = () => {
    const { uiStore } = this.props;
    uiStore.showBulletinBoard();
  }

  openPostViewDialog = (post) => {
    this.setState({
      postViewDialogOpen: true,
      openedPost: post,
    });
  }

  closePostViewDialog = () => {
    this.setState({
      postViewDialogOpen: false,
      openedPost: {},
    });
  }

  render() {
    const {
      // openedCategory,
      uiStore,
    } = this.props;
    const {
      postViewDialogOpen,
      openedPost,
      categoryBulletinBoardPosts,
      loadingPosts,
    } = this.state;

    const categoryId = uiStore.currentView.id;
    const openedCategory = uiStore.currentUser.bulletinBoardCategories.find((category) => category.id === categoryId);

    return (
      <div {...{
        style: appcontentsize,
      }}
      >
        <BulletinBoardPostsHeader bulletinBoardCategory={openedCategory} closeBulletinBoardCategory={this.closeBulletinBoardCategory} />
        <div style={{ marginTop: '25px' }}>
          {loadingPosts && (
            <div style={{ padding: '20px', textAlign: 'center' }}>
              <LoadingSpinner color="white" />
            </div>
          )}
          {categoryBulletinBoardPosts && categoryBulletinBoardPosts.length > 0 && (
            categoryBulletinBoardPosts.map((post) => (
              <BulletinBoardPostRow
                key={`bulletin-board-post-${post.id}`}
                bulletinBoardPost={post}
                openPostViewDialog={this.openPostViewDialog}
              />
            ))
          )}
        </div>
        <BulletinBoardPostViewDialog open={postViewDialogOpen} bulletinBoardPost={openedPost} handleClose={this.closePostViewDialog} />
      </div>
    );
  }
}

export default BulletinBoardCategoryView;
