import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, CardPrimaryAction } from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as PushPinIcon } from '../../assets/images/pushpin.svg';
// import { IconButton } from '@rmwc/icon-button';
// import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';

// const getRoomColor = (room, currentUser) => (currentUser.invitationsByWorkOrderId[room.workorder.id] == null ? 'rgb(107, 189, 107)' : 'rgb(243, 192, 0)');

@inject('uiStore', 'chatStore', 't')
@observer
class BulletinBoardPostRow extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     settingOpen: false,
  //   };
  // }

  async onClick(room, showChatRoom) {
    const { chatStore, uiStore } = this.props;

    if (showChatRoom) {
      uiStore.showChatRoom(room.id);
      try {
        await chatStore.markAsRead(room);
        chatStore.unreadCounts();
      } catch (err) {
        console.error(err);
      }
    } else {
      uiStore.showMessages();
    }
  }

  formatMessageAsSubtitle(currentUser, message) {
    const { t } = this.props;

    if (!message) {
      return t('messages.no_messages');
    }

    if (message.isSentBy(currentUser)) {
      return `${message.sender.firstName} ${message.sender.lastName}: ${message.content}`;
    }

    return message.content;
  }

  addEmailNotification(chatId) {
    const { chatStore } = this.props;

    chatStore.addEmailNotification(chatId);
  }

  deleteEmailNotification(emailNotification) {
    const { chatStore } = this.props;

    chatStore.deleteEmailNotification(emailNotification);
  }

  render() {
    const {
      asHeader, bulletinBoardPost, openPostViewDialog, // uiStore, unreadMessages, employerMode,
    } = this.props;
    // const { currentUser } = uiStore;
    // const { settingOpen } = this.state;

    return (
      <Card
        key={bulletinBoardPost.id}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CardPrimaryAction
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: '1 1 auto',
              width: '100%',
            }}
            onClick={() => openPostViewDialog && openPostViewDialog(bulletinBoardPost)}
          >
            <div
              style={{
                // borderLeft: `3px solid ${getRoomColor(room, currentUser)}`,
                padding: '0px 0rem 1rem 1rem',
                width: '100%',
              }}
              // className={unreadMessages > 0 ? 'unread' : ''}
            >
              <Typography {...{
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: '1.25rem',
                  marginBottom: '0.5em',
                  marginTop: '0.5em',
                },
                tag: 'h6',
                theme: 'textSecondaryOnDark',
                use: 'subtitle1',
              }}
              >
                {/* {!employerMode && (
                  <Icon
                    icon={asHeader ? 'arrow_back' : 'chat'}
                    style={{
                      paddingRight: '0.5em',
                      alignSelf: 'normal',
                    }}
                  />
                )} */}
                {/* <span>PIN</span> */}
                {bulletinBoardPost.pinned && (
                  <div style={{ marginRight: '5px' }}>
                    <SvgIcon>
                      <PushPinIcon />
                    </SvgIcon>
                  </div>
                )}

                {/* {unreadMessages > 0 && (<span className="unread chatroom">{unreadMessages > 99 ? 99 : unreadMessages}</span>)} */}
                {/* {' '} */}
                {/* {room.getChatTitle()} */}
                {bulletinBoardPost.title}
              </Typography>
              <Typography
                style={{ textTransform: 'capitalize', fontSize: '12px' }}
                tag="div"
                theme="textSecondaryOnDark"
                use="body2"
              >
                {`${bulletinBoardPost.createdAt.format('dd DD.MM.YY')} - ${bulletinBoardPost.createdBy}`}
              </Typography>
            </div>
            {asHeader !== true && (
              <Icon {...{
                icon: 'navigate_next',
                style: {
                  // padding: '0.8rem 1rem 1rem 0.5rem',
                  padding: '0.8rem 1rem 1rem 0.5rem',
                  margin: 'auto 0',
                },
              }}
              />
            )}
          </CardPrimaryAction>
          {/* {asHeader === true && (
            <div
              style={{
                padding: '0px 1rem',
              }}
            >
              <MenuSurfaceAnchor
                style={{
                  width: '50%',
                  textAlign: 'right',
                }}
              >
                <Menu
                  hoistToBody="true"
                  open={settingOpen}
                  focusOnOpen={false}
                  onClose={() => this.setState({
                    settingOpen: false,
                  })}
                  style={{
                    backgroundColor: '#1E232F',
                    // width: '100vw',
                    maxWidth: '100%',
                    zIndex: 9999,
                  }}
                >
                  <MenuItem
                    style={{
                      color: 'white',
                    }}
                    // onClick={
                    //   room.emailNotification
                    //     ? () => this.deleteEmailNotification(room.emailNotification)
                    //     : () => this.addEmailNotification(room.id)
                    // }
                  >
                    {
                      room.emailNotification
                        ? 'Poista'
                        : 'Lisää'
                    }
                    {' sähköposti-ilmoitukset'}
                  </MenuItem>
                </Menu>
                <IconButton
                  style={{ color: 'white' }}
                  icon="more_vert"
                  // onClick={() => this.setState({ settingOpen: true })}
                />

              </MenuSurfaceAnchor>
            </div>
          )} */}
        </div>
      </Card>
    );
  }
}

export default BulletinBoardPostRow;
